<template>
  <div id="print-html">
    <div class="title">{{ titleName }}</div>
    <div class="order-info dp_f">
      <div class="l">订单编号:{{ query.orderSn }}</div>
      <div class="r">创建时间:{{ orderInfo.createTime }}</div>
    </div>
    <table class="tabList one">
      <tr>
        <th class="head-title">调出仓库</th>
        <th>{{ orderInfo.outStockName }}</th>
        <th class="head-title">创建人</th>
        <th>{{ orderInfo.createUserName }}</th>
      </tr>
    </table>
    <table class="tabList two">
      <tr>
        <th class="head-title">收货仓库</th>
        <th>{{ orderInfo.inStockName }}</th>
      </tr>
    </table>
    <table class="tabList two">
      <tr>
        <th class="index-title">序号</th>
        <th class="info-title">产品名称</th>
        <th class="info-title">规格/型号</th>
        <th class="info-title">产品单位</th>
        <th class="info-title">产品品牌</th>
        <th class="info-title">{{ isOver ? orderHeadName.name.countText : '计划数量' }}</th>
        <th class="info-title" v-if="isOver">小计(元)</th>
      </tr>
      <tr v-for="(item,index) in tableData" :key="item.modelId">
        <td>{{ index + 1 }}</td>
        <td>{{ item.productName }}</td>
        <td>{{ item.modelName }}</td>
        <td v-html=" getUnitName(item)"></td>
        <td>{{ item.brandName }}</td>
        <td>{{ isOver ? item[orderHeadName.name.countValue] : item.count }}</td>
        <td v-if="isOver">{{ proxy.$filters.toYuan(item[orderHeadName.name.priceValue], '元') }}</td>
      </tr>
    </table>
    <table class="tabList two">
      <tr>
        <th class="head-title">合计数量</th>
        <th :class="isOver?'':'head-value'">{{ totalNum.total }}</th>
        <template v-if="isOver">
          <th class="head-title">合金金额（元）</th>
          <th class="head-value">{{ totalNum.price }}</th>
        </template>
      </tr>
    </table>
    <table class="tabList two" v-if="isOver">
      <tr>
        <th class="head-title">大写金额</th>
        <th>{{ totalNum.big }}</th>
      </tr>
    </table>
    <table class="tabList two" v-if="isOver">
      <tr>
        <th class="head-title">出库人</th>
        <th class="head-value">{{ orderInfo.outUserName || '' }}</th>
        <th class="head-title">出库时间</th>
        <th class="head-value">{{ orderInfo.outTime || '' }}</th>
      </tr>
    </table>
    <table class="tabList two" v-if="isOver">
      <tr>
        <th class="head-title">签收人</th>
        <th class="head-value">{{ orderInfo.inUserName || '' }}</th>
        <th class="head-title">签收时间</th>
        <th class="head-value">{{ orderInfo.inTime || '' }}</th>
      </tr>
    </table>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, nextTick, reactive, ref} from "vue";
import {convertCurrency, reactiveAssign} from "@utils/tool";
import {useRoute} from "vue-router";
import {allotConfig} from './config.js'
import {useStore} from "vuex";
import {transferDetailV2, transferGoodsListV2} from "@utils/api/inbound/transferV2"
import moment from "moment";
import {getUnitName} from "../../utils/tool/orderTools";

const store = useStore();
const route = useRoute();
const {query} = route;
const {proxy} = getCurrentInstance();
const tableData = ref([]);

const titleName = computed(() => {
  return isOver.value ? orderHeadName.name.startText : orderHeadName.name.overText
})
const isOver = computed(() => {
  return orderInfo.status === 6 || (orderInfo.status === 5 && query.orderType === '1')
})
const orderHeadName = reactive({
  name: {},
  stockName: "",
  thirdName: '',
  time: "",
})
const orderInfo = reactive({
  name: '',
  stockName: "",
  thirdName: '',
  time: "",
  orderSn: '',
  status: 0,
})

function setOrder(orderType) {
  let obj = allotConfig[orderType]
  orderHeadName.name = obj.name
  orderHeadName.stockName = obj.stockName
  orderHeadName.thirdName = obj.thirdName
}

setOrder(query.orderType)

const getGoodsDetail = () => {
  getOrder()
}

const totalNum = computed(() => {
  let total = 0
  let price = 0
  tableData.value.forEach(item => {
    total += isOver.value ? item[orderHeadName.name.countValue] : item.count || 0
    price += item[orderHeadName.name.priceValue] || 0
  })
  return {
    total,
    price: proxy.$filters.toYuan(price, '元'),
    big: convertCurrency(proxy.$filters.toYuan(price, '元'))
  }
})

const getOrder = () => {
  transferDetailV2({
    transferNo: query.orderSn,
    type: query.orderType,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    if (res.data?.createTime) {
      res.data.createTime = moment(res.data.createTime).format("YYYY年MM月DD日HH时mm分");
    }
    if (res.data?.inTime) {
      res.data.inTime = moment(res.data.inTime).format("YYYY年MM月DD日HH时mm分");
    }
    if (res.data?.outTime) {
      res.data.outTime = moment(res.data.outTime).format("YYYY年MM月DD日HH时mm分");
    }
    reactiveAssign(orderInfo, res.data);
    getGoodsList()
  })
}

getGoodsDetail()

const getGoodsList = () => {
  transferGoodsListV2({
    transferNo: query.orderSn,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    if (res.data?.list?.length > 0) {
      res.data.list.forEach((item) => {
      });
    }
    tableData.value = res.data.list || [];
    //页面渲染完成后打印
    nextTick(() => {
      proxy.$nextTick(() => {
        window.print()
      });
    });
  });
};

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
