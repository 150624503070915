export const orderConfig = {
    1: {
        name: {
            overText: '采购计划',
            startText: '采购入库单',
            countText: '入库数量',
        },
        stockName: "入库仓库",
        thirdName: '供应商名称',
        timeDesc: {
            overText: '入库时间',
            startText: '创建时间',
        },
    },
    3: {
        name: {
            overText: '销退计划',
            startText: '销退入库单',
            countText: '入库数量',
        },
        stockName: "入库仓库",
        thirdName: '客户名称',
        timeDesc: {
            overText: '入库时间',
            startText: '创建时间',
        },
    },
    7: {
        name: {
            overText: '销售计划',
            startText: '销售出库单',
            countText: '出库数量',
        },
        stockName: "出库仓库",
        thirdName: '客户名称',
        timeDesc: {
            overText: '出库时间',
            startText: '创建时间',
        },
    },
    10: {
        name: {
            overText: '采退计划',
            startText: '采退出库单',
            countText: '出库数量',
        },
        stockName: "出库仓库",
        thirdName: '供应商名称',
        timeDesc: {
            overText: '出库时间',
            startText: '创建时间',
        },
    }
}

export const allotConfig = {
    1: {
        //出库
        name: {
            overText: '调拨出库计划',
            startText: '调拨出库单',
            countText: '出库数量',
            countValue: 'outCount',
            priceValue: "outTotalPrice"
        },
        stockName: "调入仓库",
        thirdName: '调出仓库',
    },
    2: {
        //入库
        name: {
            overText: '调拨入库计划',
            startText: '调拨入库单',
            countText: '入库数量',
            countValue: 'inCount',
            priceValue: "inTotalPrice"
        },
        stockName: "调出仓库",
        thirdName: '调入仓库',
    },
}
//4 : 调拨入库
// 8 : 调拨出库
// 1 : 采购入库
// 2 : 其他入库
// 3 : 销退入库
// 4 ： 调拨入库
// 5 ： 盘点入库
// 6 ： 其他出库
// 7 ： 销售出库
// 8 ： 调拨出库
// 9 ： 盘点出库
// 10 ： 采退出库
// 11 ： 报损出库

export function isOutOrder(orderType) {
    orderType = Number(orderType)
    return [7, 8, 10, 11].includes(orderType)
}
export const testList = [
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    }, {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    }, {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },
    {
        "id": 1,
        "productName": "123",
        "modelName": "规格123123123123",
        "unitName": "单位名称申达股份三点过",
        "brandName": "品牌名称123123123",
        "price": "4444444",
        "count": '33333',
        "total": '567456345345234'
    },


]