import request from '@utils/http'

//新增调拨
export function transferAddV2(data) {
    return request({
        url: "/carton/transfer/order/v2/add",
        method: 'post',
        data,
    })
}


//编辑调拨
export function transferEditV2(data) {
    return request({
        url: "/carton/transfer/order/v2/edit",
        method: 'post',
        data
    })
}

//删除调拨
export function transferDeleteV2(data) {
    return request({
        url: "/carton/transfer/order/v2/del",
        method: 'post',
        data
    })
}

//调拨列表
export function transferListV2(data) {
    return request({
        url: "/carton/transfer/order/v2/list",
        method: 'post',
        data,
    })
}

//调拨详情
export function transferDetailV2(data) {
    return request({
        url: "/carton/transfer/order/v2/detail",
        method: 'post',
        data
    })
}

//调拨商品列表
export function transferGoodsListV2(data) {
    return request({
        url: "/carton/transfer/order/v2/product/list",
        method: 'post',
        data
    })
}

//调拨统计接口
export function transferCensusV2(data) {
    return request({
        url: "/carton/transfer/order/v2/census",
        method: 'post',
        data,
    })
}

//审核调拨订单
export function transferExamineV2(data) {
    return request({
        url: "/carton/transfer/order/v2/examine",
        method: 'post',
        data
    })
}